import { styled } from "@mui/material";

export const Wrapper = styled("span")(({ theme }) => ({
  position: "fixed",
  right: "1rem",
  bottom: "1rem",
  fontSize: "2rem",
  padding: ".2rem",
  backgroundColor: "#323235",
  borderBottom: "3px solid #fff",
  borderColor: `${[theme.palette.secondary.main]}`,
  zIndex: 1000,
  [theme.breakpoints.up("sm")]: {
    // fontSize: "3rem",
  },
}));
