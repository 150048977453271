import * as React from "react";
import { Wrapper } from "./style";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import IconButton from "@mui/material/IconButton";

export const ScrollToTop = React.memo(() => {
  const [visible, setVisible] = React.useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) return setVisible(true);
    setVisible(false);
  };

  const Scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  React.useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  if (visible) {
    return (
      <IconButton onClick={Scroll}>
        <Wrapper>
          <ArrowUpwardRounded color="secondary" fontSize="inherit" />
        </Wrapper>
      </IconButton>
    );
  }
});
