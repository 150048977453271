import Load from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "99vh" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Load size={80} color="primary" />
      </div>
    </div>
  );
};

export default Loader;
