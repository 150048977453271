import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as React from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes.js";
// import { Loader } from "./components/Loader";
import { ThemeProvider } from "@mui/material";
import theme from "./assets/theme.js";
import { Views } from "./Views";
function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <Views />
    </ThemeProvider>
  );
}

export default App;
