import * as React from "react";
import { styled } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import { ScrollToTop } from "../components/ScrollToTop";
import { Header } from "../components/Header";

import { Analytics } from "@vercel/analytics/react";

export const Wrapper = styled("section")(() => ({
  // position: "relative",
}));

const Home = React.lazy(() => import("./Home"));
const About = React.lazy(() => import("./About"));
const Services = React.lazy(() => import("./Services"));
const Preferred = React.lazy(() => import("./Preferred"));
const Signals = React.lazy(() => import("./Signals"));
const Register = React.lazy(() => import("./Registration"));

export const getRegistrationStatus = () => {
  return JSON.parse(localStorage.getItem("isRegistered")) || false;
};

export const setRegistrationStatus = (status) => {
  localStorage.setItem("isRegistered", JSON.stringify(status));
};

export const Views = () => {
  return (
    <div>
      <Analytics />
      <Router>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            {/* Home route */}
            <Route
              exact
              path={"/"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Home />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
            <Route
              exact
              path={"/about"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Header />
                    <About />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
            <Route
              exact
              path={"/register"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Header />
                    <Register />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
            <Route
              exact
              path={"/preferred"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Header />
                    <Preferred />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
            <Route
              exact
              path={"/signals"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Header />
                    <Signals />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
            <Route
              exact
              path={"/services"}
              element={
                <React.Suspense fallback={<Loader />}>
                  <Wrapper>
                    <Header />
                    <Services />
                    <Footer />
                    <ScrollToTop />
                  </Wrapper>
                </React.Suspense>
              }
            />
          </Routes>
        </React.Suspense>
      </Router>
    </div>
  );
};
