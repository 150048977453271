import { styled } from "@mui/material";
import Stack from "@mui/material/Stack";
// import Box from "@mui/material/Box";

export const Tilt = styled(Stack)(({ theme }) => ({
  backgroundColor: "#fff",
  // color: "inherit",
  color: `${[theme.palette.secondary.main]}`,
  overflowX: "hidden",
  alignItems: "center",
  position: "fixed",
  top: 0,
  paddingLeft: "1rem",
  width: "100%",
  zIndex: 1000,
  boxShadow: "0px 3px 20px 0px #5b5b5b5c",
  // paddingTop: "2rem",

  [theme.breakpoints.up("sm")]: {
    // boxShadow: "none",
    position: "fixed",
    top: "-28px",
    // borderBottomStyle: "solid",
    // borderBottomWidth: "2px",
    // // borderBottomColor: `${[theme.palette.primary.main]}`,
    // borderBottomColor: `#545454`,
    // backgroundColor: "transparent",

    transform: "rotate(2deg)",
    height: "15vh",
    padding: "3rem 5rem 0 3rem",
    width: "103%",
  },
}));

export const Wrapper = styled(Stack)(({ theme }) => ({
  // position: "relative",
  color: "inherit",

  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    position: "absolute",
    bottom: ".5rem",
    width: "90%",
  },
}));

export const NavBar = styled("nav")(({ theme }) => ({
  color: "inherit",
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform .3s ease-in-out",

  transform: "translateX(100%)",
  flexGrow: 1,
  zIndex: 1000,
  backgroundColor: "white",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: "transparent",
    position: "relative",
    transform: "initial",
    height: "initial",
    justifyContent: "end",
    flexDirection: "row",
    // marginLeft: "2rem",
    // bottom: ".5rem",
  },
}));
export const NavWrapper = styled("ul")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
  color: "inherit",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));
export const ListItem = styled("li")(({ theme }) => ({
  textAlign: "center",
  transition: "all ease .3s",
  cursor: "pointer",
  color: `${[theme.palette.secondary.main]}`,
  borderBottomWidth: "3px",
  borderBottomStyle: "solid",
  borderBottomColor: "#fff",
  // borderBottomColor: `transparent`,
  fontWeight: 500,
  "&:hover": {
    position: "relative",
    // marginTop: "5px",
    // borderTopColor: `transparent`,
  },

  margin: "1rem 0",
  [theme.breakpoints.up("sm")]: {
    // color: `#fff`,
    margin: "0 1rem",
    "&:hover": {
      borderBottomColor: `${[theme.palette.secondary.main]}`,
    },
  },
}));
