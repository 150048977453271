import { createTheme } from "@mui/material";
// import ArialB from "./fonts/ARLRDBD.ttf";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4940CD",
    },
    secondary: {
      main: "#40CD46",
    },
  },
  typography: {
    // fontFamily: '"ArialB", sans-serif',
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [ArialB],
  //     },
  //   },
  // },
});

export default theme;
