import * as React from "react";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Stack from "@mui/material/Stack";
import logoImage from "../../assets/images/logo/logo.svg";
import { ListItem, NavBar, NavWrapper, Tilt, Wrapper } from "./styles";
import { Sling as Hamburger } from "hamburger-react";
import { IconButton } from "@mui/material";
import {
  Link,
  // useLocation
} from "react-router-dom";
// import Home from "@mui/icons-material/Home";

export const Header = React.memo(() => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [scrollHeight, setScrollHeight] = React.useState(window.screenY);

  // const location = useLocation();
  const handleItemClick = () => {
    if (screenWidth > 500) return;
    setMenuOpen(!menuOpen);
  };
  const handleResize = () => setScreenWidth(window.innerWidth);
  const handleScroll = () => setScrollHeight(window.scrollY);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    // <Box sx={{ color: "white" }}>
    <Tilt
      sx={{
        position: menuOpen ? "relative" : "",
        top: 0,
        backgroundColor: scrollHeight > 200 ? "#fff" : "",
        color: scrollHeight > 200 ? "#222222" : "",
      }}
    >
      <Wrapper>
        <Link
          to="/"
          style={{
            width: "100%",
          }}
        >
          <Stack direction="row" alignItems="center">
            <ListItemIcon>
              <img src={logoImage} alt="logo" width={40} />
            </ListItemIcon>{" "}
            <Typography variant="h6" fontWeight={800} color="secondary">
              ForexHouseNg
            </Typography>
          </Stack>
        </Link>

        <NavBar sx={menuOpen && { transform: "translateX(0)" }}>
          <NavWrapper>
            <ListItem>
              <Link to="/" onClick={handleItemClick}>
                Home
              </Link>
            </ListItem>
            <ListItem>
              <Link onClick={handleItemClick} to="/services">
                Services
              </Link>
            </ListItem>
            <ListItem>
              <Link onClick={handleItemClick} to="/register">
                Register
              </Link>
            </ListItem>
            <ListItem>
              <Link onClick={handleItemClick} to="/signals">
                Signals
              </Link>
            </ListItem>
            <ListItem>
              <Link onClick={handleItemClick} to="/preferred">
                Broker
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about" onClick={handleItemClick}>
                About Us
              </Link>
            </ListItem>
          </NavWrapper>
        </NavBar>

        {screenWidth < 600 && (
          <IconButton
            onClick={() => setMenuOpen(!menuOpen)}
            color="secondary"
            sx={
              menuOpen && {
                position: "fixed",
                zIndex: 100000,
                top: "1rem",
                right: "1rem",
              }
            }
          >
            <Hamburger toggled={menuOpen} duration={1} size={27} />
          </IconButton>
        )}
      </Wrapper>
    </Tilt>
    // </Box>
  );
});
