import { styled } from "@mui/material";
// import Stack from "@mui/material/Stack";

export const Wrapper = styled("section")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  overflowX: "hidden",
  width: "100%",
  // marginTop: "1rem",
  padding: "1rem 0",
  borderTop: `2px solid ${[theme.palette.secondary.main]}`,

  backgroundColor: "#323235",
  [theme.breakpoints.up("sm")]: {
    // marginTop: "2rem",
  },
}));
export const Tilt = styled("div")(({ theme }) => ({
  // overflowX: "hidden",
  // display: "none",
  position: "absolute",
  top: "-40px",
  zIndex: -1,
  marginLeft: "-7px",
  height: "50px",
  width: "100%",
  backgroundColor: `${[theme.palette.secondary.main]}`,
  transform: "rotate(-4deg)",
  // borderTop: "1px solid #fff",
  borderBottom: "5px solid #fff",
  [theme.breakpoints.up("sm")]: {
    borderBottom: "3px solid #fff",

    display: "block",
    top: "-27px",
    marginLeft: "initial",
    transform: "rotate(-2deg)",
  },
}));
export const Body = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  textAlign: "center",
  padding: "1rem 0 0",
  color: "#fff",
}));
