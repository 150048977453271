import { Body, Tilt, Wrapper } from "./styles";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <Wrapper>
      <Tilt />
      <Body>
        <Typography variant="body2" color="inherit" sx={{ textWrap: "wrap" }}>
          &copy; {new Date().getFullYear()} Forexhouseng. All rights reserved.
        </Typography>
        <Typography variant="body2" color="inherit" sx={{ textWrap: "wrap" }}>
          Created by{" "}
          <Typography
            component="a"
            color="secondary"
            href="https://olorunda.xyz"
          >
            olorunda
          </Typography>
        </Typography>
      </Body>
    </Wrapper>
  );
};

export default Footer;
